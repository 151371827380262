import { Controller } from '@hotwired/stimulus'
import TurboQuery from '../helpers/turbolinks_helper'
import { getDefault } from '../helpers/module_helper'
import { requestJSON } from '../helpers/http'
import humanize from '../helpers/humanize_helper'
import { darkEnabled } from '../services/theme_service'
import globalEventBus from '../services/event_bus_service'

let Dygraph
const SELL = 1
const BUY = 2
const candlestick = 'candlestick'
const orders = 'orders'
const depth = 'depth'
const history = 'history'
const volume = 'volume'
const aggregatedKey = 'aggregated'
const anHour = '1h'
const minuteMap = {
  '5m': 5,
  '30m': 30,
  '1h': 60,
  '1d': 1440,
  '1mo': 43200
}
const PIPI = 2 * Math.PI
const prettyDurations = {
  '5m': '5 min',
  '30m': '30 min',
  '1h': 'hour',
  '1d': 'day',
  '1mo': 'month'
}
const exchangeLinks = {
  binance: 'https://www.binance.com/en/trade/DCR_BTC',
  bittrex: 'https://bittrex.com/Market/Index?MarketName=BTC-DCR',
  poloniex: 'https://poloniex.com/exchange#btc_dcr',
  dragonex: 'https://dragonex.io/en-us/trade/index/dcr_btc',
  huobi: 'https://www.hbg.com/en-us/exchange/?s=dcr_btc',
  dcrdex: 'https://dex.decred.org'
}

const printNames = {
  dcrdex: 'dex.decred.org'
  // default is capitalize
}

function printName (token) {
  const name = printNames[token]
  if (name) return name
  return humanize.capitalize(token)
}

const defaultZoomPct = 20
let hidden, visibilityChange
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}
let focused = true
let aggStacking = true
let refreshAvailable = false
let availableCandlesticks, availableDepths

function screenIsBig () {
  return window.innerWidth >= 992
}

function validDepthExchange (token) {
  return availableDepths.indexOf(token) > -1
}

function hasBin (xc, bin) {
  return availableCandlesticks[xc].indexOf(bin) !== -1
}

function usesOrderbook (chart) {
  return chart === depth || chart === orders
}

function usesCandlesticks (chart) {
  return chart === candlestick || chart === volume || chart === history
}

let requestCounter = 0
let responseCache = {}

function hasCache (k) {
  if (!responseCache[k]) return false
  const expiration = new Date(responseCache[k].expiration)
  return expiration > new Date()
}

function clearCache (k) {
  if (!responseCache[k]) return
  delete responseCache[k]
}

const lightStroke = '#333'
const darkStroke = '#ddd'
let chartStroke = lightStroke
let conversionFactor = 1
let btcPrice, fiatCode
const gridColor = '#7774'
let settings = {}

let colorNumerator = 0
let colorDenominator = 1
const hslS = '100%'
const hslL = '50%'
const hslOffset = 225 // 0 <= x < 360

// These are the first four hues generated by getHue(
const exchangeHues = {
  dcrdex: 'hsl(225,100%,50%)',
  binance: 'hsl(45,100%,50%)',
  bittrex: 'hsl(315,100%,50%)',
  poloniex: 'hsl(135,100%,50%)'
}

const hsl = (h) => `hsl(${(h + hslOffset) % 360},${hslS},${hslL})`
// Generates colors on the hue sequence 0, 1/2, 1/4, 3/4, 1/8, 3/8, 5/8, 7/8, 1/16, ...
function generateHue () {
  if (colorNumerator >= colorDenominator) {
    colorNumerator = 1 // reset the numerator
    colorDenominator *= 2 // double the denominator
    if (colorDenominator >= 512) { // Will generate 256 different hues
      colorNumerator = 0
      colorDenominator = 1
    }
    return generateHue()
  }
  const hue = colorNumerator / colorDenominator * 360
  colorNumerator += 2
  return hsl(hue)
}

function getHue (token) {
  if (exchangeHues[token]) return exchangeHues[token]
  exchangeHues[token] = generateHue()
  return exchangeHues[token]
}

// Generate the constant hues so dynamically assigned hues won't use them.
Object.keys(exchangeHues).forEach(generateHue)

const commonChartOpts = {
  gridLineColor: gridColor,
  axisLineColor: 'transparent',
  underlayCallback: (ctx, area, dygraph) => {
    ctx.lineWidth = 1
    ctx.strokeStyle = gridColor
    ctx.strokeRect(area.x, area.y, area.w, area.h)
  },
  // these should be set to avoid Dygraph strangeness
  labels: [' ', ' '], // To avoid an annoying console message,
  xlabel: ' ',
  ylabel: ' ',
  pointSize: 6,
  showRangeSelector: true,
  rangeSelectorPlotFillColor: '#C4CBD2',
  rangeSelectorAlpha: 0.4,
  rangeSelectorHeight: 40
}

const chartResetOpts = {
  fillGraph: false,
  strokeWidth: 2,
  drawPoints: false,
  logscale: false,
  xRangePad: 0,
  yRangePad: 0,
  stackedGraph: false,
  zoomCallback: null
}

function convertedThreeSigFigs (x) {
  return humanize.threeSigFigs(x * conversionFactor)
}

function convertedEightDecimals (x) {
  return (x * conversionFactor).toFixed(8)
}

function orderbookStats (bids, asks) {
  const bidEdge = bids[0].price
  const askEdge = asks[0].price
  const midGap = (bidEdge + askEdge) / 2
  return {
    bidEdge: bidEdge,
    askEdge: askEdge,
    gap: askEdge - bidEdge,
    midGap: midGap,
    lowCut: 0.1 * midGap, // Low cutoff of 10% market.
    highCut: midGap * 2 // High cutoff + 100%
  }
}

const dummyOrderbook = {
  pts: [[0, 0, 0]],
  outliers: {
    asks: [],
    bids: []
  }
}

function sizedArray (len, v) {
  const a = []
  for (let i = 0; i < len; i++) {
    a.push(v)
  }
  return a
}

function rangedPts (pts, cutoff) {
  const l = []
  const outliers = []
  pts.forEach(pt => {
    if (cutoff(pt)) {
      outliers.push(pt)
      return
    }
    l.push(pt)
  })
  return { pts: l, outliers: outliers }
}

function translateDepthSide (pts, idx, cutoff) {
  const sorted = rangedPts(pts, cutoff)
  let accumulator = 0
  const translated = sorted.pts.map(pt => {
    accumulator += pt.quantity
    pt = [pt.price, null, null]
    pt[idx] = accumulator
    return pt
  })
  return { pts: translated, outliers: sorted.outliers }
}

function translateDepthPoint (pt, offset, accumulator) {
  const l = sizedArray(pt.volumes.length + 1, null)
  l[0] = pt.price
  pt.volumes.forEach((vol, i) => {
    accumulator[i] += vol
    l[offset + i + 1] = accumulator[i]
  })
  return l
}

function needsDummyPoint (pt, offset, accumulator) {
  const xcCount = pt.volumes.length
  for (let i = 0; i < xcCount; i++) {
    if (pt.volumes[i] && accumulator[i] === 0) return { price: pt.price + offset, volumes: sizedArray(xcCount, 0) }
  }
  return false
}

function translateAggregatedDepthSide (pts, idx, cutoff) {
  const sorted = rangedPts(pts, cutoff)
  const xcCount = pts[0].volumes.length
  const offset = idx === SELL ? 0 : xcCount
  const zeroWidth = idx === SELL ? -1e-8 : 1e-8
  const xcAccumulator = sizedArray(xcCount, 0)
  const l = []
  sorted.pts.forEach(pt => {
    const zeros = needsDummyPoint(pt, zeroWidth, xcAccumulator)
    if (zeros) {
      l.push(translateDepthPoint(zeros, offset, xcAccumulator))
    }
    l.push(translateDepthPoint(pt, offset, xcAccumulator))
  })
  return { pts: l, outliers: sorted.outliers }
}

function translateOrderbookSide (pts, idx, cutoff) {
  const sorted = rangedPts(pts, cutoff)
  const translated = sorted.pts.map(pt => {
    const l = [pt.price, null, null]
    l[idx] = pt.quantity
    return l
  })
  return { pts: translated, outliers: sorted.outliers }
}

function sumPt (pt) {
  return pt.volumes.reduce((a, v) => { return a + v }, 0)
}

function translateAggregatedOrderbookSide (pts, idx, cutoff) {
  const sorted = rangedPts(pts, cutoff)
  const translated = sorted.pts.map(pt => {
    const l = [pt.price, null, null]
    l[idx] = sumPt(pt)
    return l
  })
  return { pts: translated, outliers: sorted.outliers }
}

function processOrderbook (response, translator) {
  const bids = response.data.bids
  const asks = response.data.asks

  if (!response.tokens) {
    // Add the dummy points to make the chart line connect to the baseline and
    // because otherwise Dygraph has a bug that adds an offset to the asks side.
    bids.splice(0, 0, { price: bids[0].price + 1e-8, quantity: 0 })
    asks.splice(0, 0, { price: asks[0].price - 1e-8, quantity: 0 })
  }
  if (!bids || !asks) {
    console.warn('no bid/ask data in API response')
    return dummyOrderbook
  }
  if (!bids.length || !asks.length) {
    console.warn('empty bid/ask data in API response')
    return dummyOrderbook
  }
  const stats = orderbookStats(bids, asks)
  const buys = translator(bids, BUY, pt => pt.price < stats.lowCut)
  buys.pts.reverse()
  const sells = translator(asks, SELL, pt => pt.price > stats.highCut)

  // Find points in overlapping region with duplicate rates, to deal with a
  // Dygraphs bug.
  let dupes
  if (response.tokens) dupes = findAggregateDupes(buys.pts, sells.pts)

  return {
    pts: buys.pts.concat(sells.pts),
    outliers: buys.outliers.concat(sells.outliers),
    stats: stats,
    dupes: dupes
  }
}

function candlestickPlotter (e) {
  if (e.seriesIndex !== 0) return

  const area = e.plotArea
  const ctx = e.drawingContext
  ctx.strokeStyle = chartStroke
  ctx.lineWidth = 1
  const sets = e.allSeriesPoints
  if (sets.length < 2) {
    // do nothing
    return
  }

  const barWidth = area.w * Math.abs(sets[0][1].x - sets[0][0].x) * 0.8
  const [opens, closes, highs, lows] = sets
  let open, close, high, low
  for (let i = 0; i < sets[0].length; i++) {
    ctx.strokeStyle = '#777'
    open = opens[i]
    close = closes[i]
    high = highs[i]
    low = lows[i]
    const centerX = area.x + open.x * area.w
    const topY = area.h * high.y + area.y
    const bottomY = area.h * low.y + area.y
    ctx.beginPath()
    ctx.moveTo(centerX, topY)
    ctx.lineTo(centerX, bottomY)
    ctx.stroke()
    ctx.strokeStyle = 'black'
    let top
    if (open.yval > close.yval) {
      ctx.fillStyle = '#f93f39cc'
      top = area.h * open.y + area.y
    } else {
      ctx.fillStyle = '#1acc84cc'
      top = area.h * close.y + area.y
    }
    const h = area.h * Math.abs(open.y - close.y)
    const left = centerX - barWidth / 2
    ctx.fillRect(left, top, barWidth, h)
    ctx.strokeRect(left, top, barWidth, h)
  }
}

function drawOrderPt (ctx, pt) {
  return drawPt(ctx, pt, orderPtSize, true)
}

function drawPt (ctx, pt, size, bordered) {
  ctx.beginPath()
  ctx.arc(pt.x, pt.y, size, 0, PIPI)
  ctx.fill()
  if (bordered) ctx.stroke()
}

function drawLine (ctx, start, end) {
  ctx.beginPath()
  ctx.moveTo(start.x, start.y)
  ctx.lineTo(end.x, end.y)
  ctx.stroke()
}

function makePt (x, y) { return { x, y } }

function canvasXY (area, pt) {
  return {
    x: area.x + pt.x * area.w,
    y: area.y + pt.y * area.h
  }
}

let orderPtSize = 7
if (!screenIsBig()) orderPtSize = 4

function orderPlotter (e) {
  if (e.seriesIndex !== 0) return

  const area = e.plotArea
  const ctx = e.drawingContext

  // let buyColor, sellColor
  const [buyColor, sellColor] = e.dygraph.getColors()

  const [buys, sells] = e.allSeriesPoints
  ctx.lineWidth = 1
  ctx.strokeStyle = darkEnabled() ? 'black' : 'white'
  for (let i = 0; i < buys.length; i++) {
    const buy = buys[i]
    const sell = sells[i]
    if (buy) {
      ctx.fillStyle = buyColor
      drawOrderPt(ctx, canvasXY(area, buy))
    }
    if (sell) {
      ctx.fillStyle = sellColor
      drawOrderPt(ctx, canvasXY(area, sell))
    }
  }
}

const greekCapDelta = String.fromCharCode(916)

function depthLegendPlotter (e) {
  const tokens = e.dygraph.getOption('tokens')
  const stats = e.dygraph.getOption('stats')

  const area = e.plotArea
  const ctx = e.drawingContext

  const dark = darkEnabled()
  const big = screenIsBig()
  const mg = e.dygraph.toDomCoords(stats.midGap, 0)
  const midGap = makePt(mg[0], mg[1])
  const fontSize = big ? 15 : 13
  ctx.textAlign = 'left'
  ctx.textBaseline = 'top'
  ctx.font = `${fontSize}px arial`
  ctx.lineWidth = 1
  ctx.strokeStyle = chartStroke
  const boxColor = dark ? '#2228' : '#fff8'

  const midGapPrice = humanize.threeSigFigs(stats.midGap)
  const deltaPctTxt = `${greekCapDelta} : ${humanize.threeSigFigs(stats.gap / stats.midGap * 100)}%`
  const fiatGapTxt = `${humanize.threeSigFigs(stats.gap * btcPrice)} ${fiatCode}`
  const btcGapTxt = `${humanize.threeSigFigs(stats.gap)} BTC`
  let boxW = 0
  const txts = [fiatGapTxt, btcGapTxt, deltaPctTxt, midGapPrice]
  txts.forEach(txt => {
    const w = ctx.measureText(txt).width
    if (w > boxW) boxW = w
  })
  let rowHeight = fontSize * 1.5
  const rowPad = big ? (rowHeight - fontSize) / 2 : (rowHeight - fontSize) / 3
  const boxPad = big ? rowHeight / 3 : rowHeight / 5
  let x
  let y = big ? fontSize * 2 : fontSize

  // If it's an aggregated chart, start with a color legend
  if (tokens) {
    // If this is an aggregated chart, draw the color legend first
    const ptSize = fontSize / 3
    let legW = 0
    tokens.forEach(token => {
      const w = ctx.measureText(token).width + rowHeight// leave space for dot
      if (w > legW) legW = w
    })
    x = midGap.x - legW / 2
    const boxH = rowHeight * tokens.length
    ctx.fillStyle = boxColor
    const rect = makePt(x - boxPad, y - boxPad)
    const dims = makePt(legW + boxPad * 4, boxH + boxPad * 2)
    ctx.fillRect(rect.x, rect.y, dims.x, dims.y)
    ctx.strokeRect(rect.x, rect.y, dims.x, dims.y)
    tokens.forEach(token => {
      ctx.fillStyle = getHue(token)
      drawPt(ctx, makePt(x + rowHeight / 2, y + rowHeight / 2 - 1), ptSize)
      ctx.fillStyle = chartStroke
      ctx.fillText(token, x + rowPad + rowHeight, y + rowPad)
      y += rowHeight
    })
    y += boxPad * 3
    x = midGap.x - boxW / 2
  } else {
    y += area.h / 4
    x = midGap.x - boxW / 2 - 25
  }
  // Label the gap size.
  rowHeight -= 2 // just looks better
  ctx.fillStyle = boxColor
  const rect = makePt(x - boxPad, y - boxPad)
  const dims = makePt(boxW + boxPad * 3, rowHeight * 4 + boxPad * 2)
  ctx.fillRect(rect.x, rect.y, dims.x, dims.y)
  ctx.strokeRect(rect.x, rect.y, dims.x, dims.y)
  ctx.fillStyle = chartStroke
  const centerX = x + (boxW / 2)
  const write = s => {
    const cornerX = centerX - (ctx.measureText(s).width / 2)
    ctx.fillText(s, cornerX + rowPad, y + rowPad)
    y += rowHeight
  }

  ctx.save()
  ctx.font = `bold ${fontSize}px arial`
  write(midGapPrice)
  ctx.restore()
  write(deltaPctTxt)
  write(fiatGapTxt)
  write(btcGapTxt)

  // Draw a line from the box to the gap
  drawLine(ctx,
    makePt(x + boxW / 2, y + boxPad * 2 + boxPad),
    makePt(midGap.x, midGap.y - boxPad))
}

function depthPlotter (e) {
  Dygraph.Plotters.fillPlotter(e)
  const tokens = e.dygraph.getOption('tokens')
  if (tokens && e.dygraph.getOption('stackedGraph')) {
    if (e.seriesIndex === 0 || e.seriesIndex === tokens.length) {
      e.color = chartStroke
    } else {
      e.color = 'transparent'
    }
    fixAggregateStacking(e)
  }

  Dygraph.Plotters.linePlotter(e)

  // Callout box with color legend
  if (e.seriesIndex === e.allSeriesPoints.length - 1) depthLegendPlotter(e)
}

let stickZoom, orderZoom
function calcStickWindow (start, end, bin) {
  const halfBin = minuteMap[bin] / 2
  start = new Date(start.getTime())
  end = new Date(end.getTime())
  return [
    start.setMinutes(start.getMinutes() - halfBin),
    end.setMinutes(end.getMinutes() + halfBin)
  ]
}

export default class extends Controller {
  static get targets () {
    return ['chartSelect', 'exchanges', 'bin', 'chart', 'legend', 'conversion',
      'xcName', 'xcLogo', 'actions', 'sticksOnly', 'depthOnly', 'chartLoader',
      'xcRow', 'xcIndex', 'price', 'age', 'ageSpan', 'link', 'aggOption',
      'aggStack', 'zoom']
  }

  async connect () {
    this.isHomepage = !window.location.href.includes('/market')
    this.query = new TurboQuery()
    settings = TurboQuery.nullTemplate(['chart', 'xc', 'bin'])
    if (!this.isHomepage) {
      this.query.update(settings)
    } else {
      settings.chart = 'history'
      let hasBinance = false
      if (this.exchangesTarget.options.length > 0) {
        for (let i = 0; i < this.exchangesTarget.options.length; i++) {
          const item = this.exchangesTarget.options[i]
          // if exchange is binance, select
          if (item.value === 'binance') {
            hasBinance = true
            settings.xc = item.value
            settings.bin = '1mo'
          }
        }
        if (!hasBinance) {
          const defaultOption = this.exchangesTarget.options[0]
          settings.xc = defaultOption.value
          settings.bin = '1d'
        }
      }
    }
    this.processors = {
      orders: this.processOrders,
      candlestick: this.processCandlesticks,
      history: this.processHistory,
      depth: this.processDepth.bind(this),
      volume: this.processVolume
    }
    commonChartOpts.labelsDiv = this.legendTarget
    this.converted = false
    btcPrice = parseFloat(this.conversionTarget.dataset.factor)
    fiatCode = this.conversionTarget.dataset.code
    this.binButtons = this.binTarget.querySelectorAll('button')
    this.lastUrl = null
    this.zoomButtons = this.zoomTarget.querySelectorAll('button')
    this.zoomCallback = this._zoomCallback.bind(this)
    availableCandlesticks = {}
    availableDepths = []
    this.exchangeOptions = []
    let opts = this.exchangesTarget.options
    for (let i = 0; i < opts.length; i++) {
      const option = opts[i]
      this.exchangeOptions.push(option)
      if (option.dataset.sticks) {
        availableCandlesticks[option.value] = option.dataset.bins.split(';')
      }
      if (option.dataset.depth) availableDepths.push(option.value)
    }

    this.chartOptions = []
    opts = this.chartSelectTarget.options
    for (let i = 0; i < opts.length; i++) {
      this.chartOptions.push(opts[i])
    }

    if (settings.chart == null) {
      settings.chart = depth
    }
    if (settings.xc == null) {
      settings.xc = usesOrderbook(settings.chart) ? aggregatedKey : 'binance'
    }
    if (settings.stack) {
      settings.stack = parseInt(settings.stack)
      if (settings.stack === 0) aggStacking = false
    }
    this.setExchangeName()
    if (settings.bin == null) {
      settings.bin = anHour
    }

    this.setButtons()

    this.resize = this._resize.bind(this)
    window.addEventListener('resize', this.resize)
    this.tabVis = this._tabVis.bind(this)
    document.addEventListener(visibilityChange, this.tabVis)
    this.processNightMode = this._processNightMode.bind(this)
    globalEventBus.on('NIGHT_MODE', this.processNightMode)
    this.processXcUpdate = this._processXcUpdate.bind(this)
    globalEventBus.on('EXCHANGE_UPDATE', this.processXcUpdate)
    if (darkEnabled()) chartStroke = darkStroke
    this.setNameDisplay()
    this.fetchInitialData()
  }

  disconnect () {
    responseCache = {}
    window.removeEventListener('resize', this.resize)
    document.removeEventListener(visibilityChange, this.tabVis)
    globalEventBus.off('NIGHT_MODE', this.processNightMode)
    globalEventBus.off('EXCHANGE_UPDATE', this.processXcUpdate)
  }

  _resize () {
    if (this.graph) {
      orderPtSize = screenIsBig() ? 7 : 4
      this.graph.resize()
    }
    this.setNameDisplay()
  }

  setNameDisplay () {
    if (screenIsBig()) {
      this.xcNameTarget.classList.remove('d-hide')
    } else {
      this.xcNameTarget.classList.add('d-hide')
    }
  }

  _tabVis () {
    focused = !document[hidden]
    if (focused && refreshAvailable) this.refreshChart()
  }

  async fetchInitialData () {
    Dygraph = await getDefault(
      import(/* webpackChunkName: "dygraphs" */ '../vendor/dygraphs.min.js')
    )
    const dummyGraph = new Dygraph(document.createElement('div'), [[0, 1]], { labels: ['', ''] })

    // A little hack to start with the default interaction model. Updating the
    // interactionModel with updateOptions later does not appear to work.
    const model = dummyGraph.getOption('interactionModel')
    model.mousedown = (event, g, context) => {
      // End panning even if the mouseup event is not on the chart.
      const mouseup = () => {
        context.isPanning = false
        document.removeEventListener('mouseup', mouseup)
      }
      document.addEventListener('mouseup', mouseup)
      context.initializeMouseDown(event, g, context)
      Dygraph.startPan(event, g, context)
    }
    model.mouseup = (event, g, context) => {
      if (!context.isPanning) return
      Dygraph.endPan(event, g, context)
      context.isPanning = false // I think Dygraph is supposed to set this, but they don't.
      const zoomCallback = g.getOption('zoomCallback')
      if (zoomCallback) {
        const range = g.xAxisRange()
        zoomCallback(range[0], range[1], g.yAxisRanges())
      }
    }
    model.mousemove = (event, g, context) => {
      if (!context.isPanning) return
      Dygraph.movePan(event, g, context)
    }
    commonChartOpts.interactionModel = model

    this.graph = new Dygraph(this.chartTarget, [[0, 0], [0, 1]], commonChartOpts)
    this.fetchChart()
  }

  async fetchChart (isRefresh) {
    let url = null
    requestCounter++
    const thisRequest = requestCounter
    const bin = settings.bin
    const xc = settings.xc
    const chart = settings.chart
    const oldZoom = this.graph.xAxisRange()
    if (usesCandlesticks(chart)) {
      if (!(xc in availableCandlesticks)) {
        console.warn('invalid candlestick exchange:', xc)
        return
      }
      if (availableCandlesticks[xc].indexOf(bin) === -1) {
        console.warn('invalid bin:', bin)
        return
      }
      url = `/api/chart/market/${xc}/candlestick/${bin}`
    } else if (usesOrderbook(chart)) {
      if (!validDepthExchange(xc)) {
        console.warn('invalid depth exchange:', xc)
        return
      }
      url = `/api/chart/market/${xc}/depth`
    }
    if (!url) {
      console.warn('invalid chart:', chart)
      return
    }

    this.chartLoaderTarget.classList.add('loading')

    let response
    if (hasCache(url)) {
      response = responseCache[url]
    } else {
      // response = await axios.get(url)
      response = await requestJSON(url)
      responseCache[url] = response
      if (thisRequest !== requestCounter) {
        // new request was issued while waiting.
        this.chartLoaderTarget.classList.remove('loading')
        return
      }
    }
    // Fiat conversion only available for order books for now.
    if (usesOrderbook(chart)) {
      this.conversionTarget.classList.remove('d-hide')
      this.ageSpanTarget.dataset.age = response.data.time
      this.ageSpanTarget.textContent = humanize.timeSince(response.data.time)
      this.ageTarget.classList.remove('d-hide')
    } else {
      this.conversionTarget.classList.add('d-hide')
      this.ageTarget.classList.add('d-hide')
    }
    this.graph.updateOptions(chartResetOpts, true)
    this.graph.updateOptions(this.processors[chart](response))
    if (!this.isHomepage) {
      this.query.replace(settings)
    }
    if (isRefresh) this.graph.updateOptions({ dateWindow: oldZoom })
    else this.resetZoom()
    this.chartLoaderTarget.classList.remove('loading')
    this.lastUrl = url
    refreshAvailable = false
  }

  processCandlesticks (response) {
    const halfDuration = minuteMap[settings.bin] / 2
    const data = response.sticks.map(stick => {
      const t = new Date(stick.start)
      t.setMinutes(t.getMinutes() + halfDuration)
      return [t, stick.open, stick.close, stick.high, stick.low]
    })
    if (data.length === 0) return
    // limit to 50 points to start. Too many candlesticks = bad.
    let start = data[0][0]
    if (data.length > 50) {
      start = data[data.length - 50][0]
    }
    stickZoom = calcStickWindow(start, data[data.length - 1][0], settings.bin)
    return {
      file: data,
      labels: ['time', 'open', 'close', 'high', 'low'],
      xlabel: 'Time',
      ylabel: 'Price (BTC)',
      plotter: candlestickPlotter,
      axes: {
        x: {
          axisLabelFormatter: Dygraph.dateAxisLabelFormatter
        },
        y: {
          axisLabelFormatter: humanize.threeSigFigs,
          valueFormatter: humanize.threeSigFigs
        }
      }
    }
  }

  processHistory (response) {
    const halfDuration = minuteMap[settings.bin] / 2
    return {
      file: response.sticks.map(stick => {
        const t = new Date(stick.start)
        t.setMinutes(t.getMinutes() + halfDuration)
        // Not sure what the best way to reduce a candlestick to a single number
        // Trying this simple approach for now.
        const avg = (stick.open + stick.close + stick.high + stick.low) / 4
        return [t, avg]
      }),
      labels: ['time', 'price'],
      xlabel: 'Time',
      ylabel: 'Price (BTC)',
      colors: [chartStroke],
      plotter: Dygraph.Plotters.linePlotter,
      axes: {
        x: {
          axisLabelFormatter: Dygraph.dateAxisLabelFormatter
        },
        y: {
          axisLabelFormatter: humanize.threeSigFigs,
          valueFormatter: humanize.threeSigFigs
        }
      },
      strokeWidth: 3
    }
  }

  processVolume (response) {
    const halfDuration = minuteMap[settings.bin] / 2
    return {
      file: response.sticks.map(stick => {
        const t = new Date(stick.start)
        t.setMinutes(t.getMinutes() + halfDuration)
        return [t, stick.volume]
      }),
      labels: ['time', 'volume'],
      xlabel: 'Time',
      ylabel: `Volume (DCR / ${prettyDurations[settings.bin]})`,
      colors: [chartStroke],
      plotter: Dygraph.Plotters.linePlotter,
      axes: {
        x: {
          axisLabelFormatter: Dygraph.dateAxisLabelFormatter
        },
        y: {
          axisLabelFormatter: humanize.threeSigFigs,
          valueFormatter: humanize.threeSigFigs
        }
      },
      strokeWidth: 3
    }
  }

  processDepth (response) {
    if (response.tokens) {
      return this.processAggregateDepth(response)
    }
    const data = processOrderbook(response, translateDepthSide)
    return {
      labels: ['price', 'cumulative sell', 'cumulative buy'],
      file: data.pts,
      fillGraph: true,
      colors: ['#ed6d47', '#41be53'],
      xlabel: `Price (${this.converted ? fiatCode : 'BTC'})`,
      ylabel: 'Volume (DCR)',
      tokens: null,
      stats: data.stats,
      plotter: depthPlotter, // Don't use Dygraph.linePlotter here. fillGraph won't work.
      zoomCallback: this.zoomCallback,
      axes: {
        x: {
          axisLabelFormatter: convertedThreeSigFigs,
          valueFormatter: convertedEightDecimals
        },
        y: {
          axisLabelFormatter: humanize.threeSigFigs,
          valueFormatter: humanize.threeSigFigs
        }
      }
    }
  }

  processAggregateDepth (response) {
    // Re-order the data so that deepest books are first.
    reorderAggregateData(response)
    const tokens = response.tokens
    const data = processOrderbook(response, translateAggregatedDepthSide)
    const xcCount = tokens.length
    const keys = sizedArray(xcCount * 2 + 1, null)
    keys[0] = 'price'
    const colors = sizedArray(xcCount * 2, null)
    for (let i = 0; i < xcCount; i++) {
      const token = tokens[i]
      const color = getHue(token)
      keys[i + 1] = ` ${token} sell`
      keys[xcCount + i + 1] = ` ${token} buy`
      colors[i] = color
      colors[xcCount + i] = color
    }
    return {
      labels: keys,
      file: data.pts,
      colors: colors,
      xlabel: `Price (${this.converted ? fiatCode : 'BTC'})`,
      ylabel: 'Volume (DCR)',
      plotter: depthPlotter,
      fillGraph: aggStacking,
      stackedGraph: aggStacking,
      tokens: tokens,
      stats: data.stats,
      dupes: data.dupes,
      zoomCallback: this.zoomCallback,
      axes: {
        x: {
          axisLabelFormatter: convertedThreeSigFigs,
          valueFormatter: convertedEightDecimals
        },
        y: {
          axisLabelFormatter: humanize.threeSigFigs,
          valueFormatter: humanize.threeSigFigs
        }
      }
    }
  }

  processOrders (response) {
    const data = processOrderbook(response, response.tokens ? translateAggregatedOrderbookSide : translateOrderbookSide)
    return {
      labels: ['price', 'sell', 'buy'],
      file: data.pts,
      colors: ['#f93f39cc', '#1acc84cc'],
      xlabel: `Price (${this.converted ? fiatCode : 'BTC'})`,
      ylabel: 'Volume (DCR)',
      plotter: orderPlotter,
      axes: {
        x: {
          axisLabelFormatter: convertedThreeSigFigs
        },
        y: {
          axisLabelFormatter: humanize.threeSigFigs,
          valueFormatter: humanize.threeSigFigs
        }
      },
      stats: data.stats,
      strokeWidth: 0,
      drawPoints: true,
      logscale: true,
      xRangePad: 15,
      yRangePad: 15
    }
  }

  justifyBins () {
    const bins = availableCandlesticks[settings.xc]
    if (bins.indexOf(settings.bin) === -1) {
      settings.bin = bins[0]
      this.setBinSelection()
    }
  }

  setButtons () {
    this.chartSelectTarget.value = settings.chart
    this.exchangesTarget.value = settings.xc
    if (usesOrderbook(settings.chart)) {
      this.binTarget.classList.add('d-hide')
      this.aggOptionTarget.disabled = false
      this.zoomTarget.classList.remove('d-hide')
    } else {
      this.binTarget.classList.remove('d-hide')
      this.aggOptionTarget.disabled = true
      this.zoomTarget.classList.add('d-hide')
      this.binButtons.forEach(button => {
        if (hasBin(settings.xc, button.name)) {
          button.classList.remove('d-hide')
        } else {
          button.classList.add('d-hide')
        }
      })
      this.setBinSelection()
    }
    const sticksDisabled = !availableCandlesticks[settings.xc]
    this.sticksOnlyTargets.forEach(option => {
      option.disabled = sticksDisabled
    })
    const depthDisabled = !validDepthExchange(settings.xc)
    this.depthOnlyTargets.forEach(option => {
      option.disabled = depthDisabled
    })
    if (settings.xc === aggregatedKey && settings.chart === depth) {
      this.aggStackTarget.classList.remove('d-hide')
      settings.stack = aggStacking ? 1 : 0
    } else {
      this.aggStackTarget.classList.add('d-hide')
      settings.stack = null
    }
  }

  setBinSelection () {
    const bin = settings.bin
    this.binButtons.forEach(button => {
      if (button.name === bin) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    })
  }

  changeGraph (e) {
    const target = e.target || e.srcElement
    settings.chart = target.value
    if (usesCandlesticks(settings.chart)) {
      this.justifyBins()
    }
    this.setButtons()
    this.fetchChart()
  }

  changeExchange () {
    settings.xc = this.exchangesTarget.value
    this.setExchangeName()
    if (usesCandlesticks(settings.chart)) {
      if (!availableCandlesticks[settings.xc]) {
        // exchange does not have candlestick data
        // show the depth chart.
        settings.chart = depth
      } else {
        this.justifyBins()
      }
    }
    this.setButtons()
    this.fetchChart()
    this.resetZoom()
  }

  setExchange (e) {
    let node = e.target || e.srcElement
    while (node && node.nodeName !== 'TR') node = node.parentNode
    if (!node || !node.dataset || !node.dataset.token) return
    this.exchangesTarget.value = node.dataset.token
    this.changeExchange()
  }

  changeBin (e) {
    const btn = e.target || e.srcElement
    if (btn.nodeName !== 'BUTTON' || !this.graph) return
    settings.bin = btn.name
    this.justifyBins()
    this.setBinSelection()
    this.fetchChart()
  }

  resetZoom () {
    if (settings.chart === candlestick) {
      this.graph.updateOptions({ dateWindow: stickZoom })
    } else if (usesOrderbook(settings.chart)) {
      if (orderZoom) this.graph.updateOptions({ dateWindow: orderZoom })
      else this.setZoomPct(defaultZoomPct)
    } else {
      this.graph.resetZoom()
    }
  }

  refreshChart () {
    refreshAvailable = true
    if (!focused) {
      return
    }
    this.fetchChart(true)
  }

  setConversion (e) {
    const btn = e.target || e.srcElement
    if (btn.nodeName !== 'BUTTON' || !this.graph) return
    this.conversionTarget.querySelectorAll('button').forEach(b => b.classList.remove('btn-selected'))
    btn.classList.add('btn-selected')
    let cLabel = 'BTC'
    if (e.target.name === 'BTC') {
      this.converted = false
      conversionFactor = 1
    } else {
      this.converted = true
      conversionFactor = btcPrice
      cLabel = fiatCode
    }
    this.graph.updateOptions({ xlabel: `Price (${cLabel})` })
  }

  setExchangeName () {
    this.xcLogoTarget.className = `exchange-logo ${settings.xc}`
    const prettyName = printName(settings.xc)
    this.xcNameTarget.textContent = prettyName
    const href = exchangeLinks[settings.xc]
    if (href) {
      this.linkTarget.href = href
      this.linkTarget.textContent = `Visit ${prettyName}`
      this.actionsTarget.classList.remove('d-hide')
    } else {
      this.actionsTarget.classList.add('d-hide')
    }
  }

  _processNightMode (data) {
    if (!this.graph) return
    chartStroke = data.nightMode ? darkStroke : lightStroke
    if (settings.chart === history || settings.chart === volume) {
      this.graph.updateOptions({ colors: [chartStroke] })
    }
    if (settings.chart === orders || settings.chart === depth) {
      this.graph.setAnnotations([])
    }
  }

  getExchangeRow (token) {
    const rows = this.xcRowTargets
    for (let i = 0; i < rows.length; i++) {
      const tr = rows[i]
      if (tr.dataset.token === token) {
        const row = {}
        tr.querySelectorAll('td').forEach(td => {
          switch (td.dataset.type) {
            case 'price':
              row.price = td
              break
            case 'volume':
              row.volume = td
              break
            case 'fiat':
              row.fiat = td
              break
            case 'arrow':
              row.arrow = td.querySelector('span')
              break
          }
        })
        return row
      }
    }
    return null
  }

  setStacking (e) {
    const btn = e.target || e.srcElement
    if (btn.nodeName !== 'BUTTON' || !this.graph) return
    this.aggStackTarget.querySelectorAll('button').forEach(b => b.classList.remove('btn-selected'))
    btn.classList.add('btn-selected')
    aggStacking = btn.name === 'on'
    this.graph.updateOptions({ stackedGraph: aggStacking, fillGraph: aggStacking })
  }

  setZoom (e) {
    const btn = e.target || e.srcElement
    if (btn.nodeName !== 'BUTTON' || !this.graph) return
    this.setZoomPct(parseInt(btn.name))
    const stats = this.graph.getOption('stats')
    const spread = stats.midGap * parseFloat(btn.name) / 100
    this.graph.updateOptions({ dateWindow: [stats.midGap - spread, stats.midGap + spread] })
  }

  setZoomPct (pct) {
    this.zoomButtons.forEach(b => {
      if (parseInt(b.name) === pct) b.classList.add('btn-selected')
      else b.classList.remove('btn-selected')
    })
    const stats = this.graph.getOption('stats')
    const spread = stats.midGap * pct / 100
    let low = stats.midGap - spread
    let high = stats.midGap + spread
    const [min, max] = this.graph.xAxisExtremes()
    if (low < min) low = min
    if (high > max) high = max
    orderZoom = [low, high]
    this.graph.updateOptions({ dateWindow: orderZoom })
  }

  _zoomCallback (start, end) {
    orderZoom = [start, end]
    this.zoomButtons.forEach(b => b.classList.remove('btn-selected'))
  }

  _processXcUpdate (update) {
    const xc = update.updater
    const cType = xc.chain_type
    if (cType && cType !== 'dcr') {
      return
    }
    if (update.fiat) { // btc-fiat exchange update
      this.xcIndexTargets.forEach(span => {
        if (span.dataset.token === xc.token) {
          span.textContent = humanize.commaWithDecimal(xc.price, 2)
        }
      })
    } else { // dcr-btc exchange update
      const row = this.getExchangeRow(xc.token)
      row.volume.textContent = humanize.threeSigFigs(xc.volume)
      row.price.textContent = humanize.threeSigFigs(xc.price)
      row.fiat.textContent = (xc.price * update.btc_price).toFixed(2)
      if (xc.change === 0) {
        row.arrow.className = ''
      } else if (xc.change > 0) {
        row.arrow.className = 'dcricon-arrow-up text-green'
      } else {
        row.arrow.className = 'dcricon-arrow-down text-danger'
      }
    }
    // Update the big displayed value and the aggregated row
    const fmtPrice = update.price.toFixed(2)
    this.priceTarget.textContent = fmtPrice
    const aggRow = this.getExchangeRow(aggregatedKey)
    btcPrice = update.btc_price
    aggRow.price.textContent = humanize.threeSigFigs(update.price / btcPrice)
    aggRow.volume.textContent = humanize.threeSigFigs(update.volume)
    aggRow.fiat.textContent = fmtPrice
    // Auto-update the chart if it makes sense.
    if (settings.xc !== aggregatedKey && settings.xc !== xc.token) return
    if (settings.xc === aggregatedKey &&
        hasCache(this.lastUrl) &&
        responseCache[this.lastUrl].tokens.indexOf(update.updater) === -1) return
    if (usesOrderbook(settings.chart)) {
      clearCache(this.lastUrl)
      this.refreshChart()
    } else if (usesCandlesticks(settings.chart)) {
      // Only show refresh button if cache is expired
      if (!hasCache(this.lastUrl)) {
        this.refreshChart()
      }
    }
  }
}

function aggregateSums (side, sums, tokens, cutoff) {
  for (const pt of side) {
    if (cutoff(pt.price)) continue
    for (const i in tokens) sums[i][1] += pt.volumes[i]
  }
}

/*
 * reorderAggregateData reorders the aggregated order book data so that the
 * deepest books are first.
 */
function reorderAggregateData (response) {
  let tokens = response.tokens
  const sums = []
  for (const token of tokens) sums.push([token, 0])

  const stats = orderbookStats(response.data.bids, response.data.asks)

  aggregateSums(response.data.bids, sums, tokens, v => v < stats.lowCut)
  aggregateSums(response.data.asks, sums, tokens, v => v > stats.highCut)

  sums.sort((a, b) => a[1] - b[1])

  const idxKey = {}
  for (const i in sums) idxKey[sums[i][0]] = i

  const reorder = side => {
    for (const pt of side) {
      const v = []
      for (const i in pt.volumes) v[idxKey[tokens[i]]] = pt.volumes[i]
      pt.volumes = v
    }
  }
  reorder(response.data.bids)
  reorder(response.data.asks)

  response.tokens = tokens = sums.map(v => v[0])
}

/*
 * findAggregateDupes finds price bins in the aggregated depth chart data that
 * have entries on both the buy and sell sides. Dygraphs doesn't handle the
 * duplicates well during drawing, so we will try to clean up the Dygraphs data
 * before passing it to the plotter.
 */
function findAggregateDupes (buys, sells) {
  const dupes = []
  if (sells.length) {
    let sellIdx = 0
    let sellPrice = sells[sellIdx][0]

    for (const i in buys) {
      const buyPrice = buys[i][0]
      if (buyPrice < sellPrice) continue

      while (buyPrice > sellPrice) {
        sellIdx++
        if (sellIdx >= sells.length) return dupes
        sellPrice = sells[sellIdx][0]
      }
      if (Math.round(buyPrice * 1e8) === Math.round(sellPrice * 1e8)) {
        // Found a duplicate.
        dupes.push({
          price: buyPrice,
          i: buys.length + sellIdx,
          buy: buys[i],
          sell: sells[sellIdx]
        })
      }
    }
  }
  return dupes
}

/*
 * fixAggregateStacking attempts to correct a Dygraphs limitation where stacked
 * plots don't display right when 1) the data isn't monotionically increasing in
 * price, and 2) there is an exact match on price on the doubled back region.
 */
function fixAggregateStacking (e) {
  if (e.setName.endsWith('buy')) return // only sell sides need fixing
  const dupes = e.dygraph.getOption('dupes')
  if (!dupes || dupes.length === 0) return
  let dupeIdx = 0
  let dupe = dupes[dupeIdx]
  // var dataIdx = e.seriesIndex + 1
  // var accume = 0
  // var accumeStacked = 0
  const pts = e.points
  for (let i = dupe.i; i < pts.length; i++) {
    const pt = pts[i]
    if (dupe && i === dupe.i) {
      // Need to adjust this one. Find a way to find a mapping from value to
      // ratio to canvas position.

      // Figure out how much buy order is mistakenly added.
      const misplacedVal = dupe.buy.reduce((acc, v) => { return i === 0 ? acc : acc + v }, 0)
      const subRatio = misplacedVal / e.axis.maxyval
      // Fixing these three values doesn't actually seem to affect the display,
      // but fixing them anyway.
      pt.y += subRatio
      pt.y_stacked += subRatio
      pt.yval_stacked -= misplacedVal
      // This line is the ticket to remove the dark black outline on the spike.
      pt.canvasy += subRatio * e.plotArea.h

      // TODO: Figure out how to add in missed accumulation, since the Dygraph
      // bug seems to ignore the actual sell value. Or just dump Dygraphs and
      // use canvas directly.
      // accumeStacked += dupe.sell.reduce((acc, v) => { return i === 0 ? acc : acc + v}, 0)
      // accume += dupe.sell[dataIdx]

      dupeIdx++
      if (dupeIdx >= dupes.length) dupe = null
      else dupe = dupes[dupeIdx]
    }
  }
}
